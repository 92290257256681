<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Training Details</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools">
            <div class="my-2 mr-3">
              <a
                @click="redirectToBack()"
                class="btn btn-primary font-weight-bolder"
              >
                Back
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form
          class="form"
          novalidate="novalidate"
          id="kt_business_account_form"
        >
          <!--begin::Example-->
          <div class="example mb-10">
            <v-form ref="form" lazy-validation>
              <v-row>
                <v-col cols="12" sm="2">
                  <b>Training:</b> 
                </v-col>
                <v-col cols="12" sm="4">
                  {{ training.name }} 
                </v-col>
                <v-col cols="12" sm="2">
                  <b>Category: </b>
                </v-col>
                <v-col cols="12" sm="4">
                  {{ training.category_name }} 
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="2">
                  <b>Description:</b> 
                </v-col>
                <v-col cols="12" sm="4">
                  {{ training.description }} 
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="2">
                  <b>Price per Employee: </b>
                </v-col>
                <v-col cols="12" sm="4">
                  ${{ training.price }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="2">
                  <b>Video:</b>
                </v-col>
                <v-col cols="12" sm="4">
                  <!-- a @click="downloadVideo()" download>
                    Download Video
                  </a> -->
                  <template>
                      <Media 
                        :kind="'video'"
                        :controls="true"
                        v-bind:src="training.videoPath"
                        style="width:100%" 
                      >
                      </Media>
                  </template>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import EventBus from "@/core/services/store/event-bus";
import { GET_COMPANY } from "@/core/services/store/user.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import Media from '@dongido/vue-viaudio';

export default {
  name: "ViewTraining",
  vuetify: new Vuetify(),
  components: {
    Media
  },
  data() {
    return {
      back_url: process.env.VUE_APP_ACBACK_URL.slice(0, -5),
      training: {
        name: "",
        category_name: "",
        path: "",
        description: "",
        price: 0,
        videoPath: ""
      },
    };
  },
  computed: {
    ...mapGetters(["layoutConfig","getAuthUser"]),
  },
  mounted() {
    let context = this;

    this.$store.dispatch(GET_COMPANY);
    EventBus.$on("GET_COMPANY", function() {});

    // set the tab from previous
    context.$store.dispatch(SET_BREADCRUMB, [{ title: "Training Details" }]);
    context.getTraining();
  },
  methods: {
    downloadVideo() {
      let url =
        process.env.VUE_APP_DOC_URL +
        "api/download-training-video/" +
        this.$route.params.id;
      window.open(url);
    },
    redirectToBack() {
      var context = this;
      context.$router.push({ name: "my-trainings.list" });
    },
    getTraining() {
      let context = this;
      axios({
        method: "get",
        url: "training/" + this.$route.params.id,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.training.name) {
            context.training = result.data.training;
          } else {
            Swal.fire(result.data.message, "", "error");
          }
        },
        function() {
          Swal.fire("Error", "Training fetch error!", "error");
        }
      );
    }
  }
};
</script>
